import { Directive, ElementRef, HostListener, Input } from '@angular/core';

/**
 * Directive to handle image zoom. Sets the child components z-index to -1 and the image as background on mouse click.
 * On mouse the background position is updated so the user can see every corner of the image
 */
@Directive({
  selector: '[atxImageZoom]',
  standalone: false,
})
export class ImageZoomDirective {
  /** true indicates if the zoom is active, while it's active the image moves according to the mouse location */
  private zooming = false;
  /** url for the image to be used as zoom */
  private imageUrl: string;

  /** Assigns the image url */
  @Input('atxImageZoom')
  public set imageZoom(imageUrl: string) {
    if (imageUrl) {
      this.imageUrl = imageUrl;
      this.hostElement.classList.add('zoom-ctrl');
      this.hostElement.classList.remove('active');
      this.clearBackground();
      this.zooming = false;
    } else {
      this.hostElement.classList.remove('zoom-ctrl');
    }
  }

  /** native element to which the directive was added */
  private hostElement: HTMLElement;

  /** has the client rect information of the host element.
   * It is calculated every time the element is clicked, in case the window changed
   */
  private clientRect: { left: number; top: number };

  private mouseMoveListener = this.updateBackgroundPosition.bind(this);

  /**
   * constructor
   * @param el
   */
  constructor(el: ElementRef) {
    this.hostElement = el.nativeElement;
  }

  /**
   * Process the zoom toggle to update the state of the host element
   * @param $event click event
   */
  @HostListener('click', ['$event'])
  protected toggleZoom($event: MouseEvent) {
    if (this.imageUrl) {
      this.zooming = !this.zooming;
      this.clientRect = this.hostElement.getBoundingClientRect();
      this.updateBackground();
      this.updateBackgroundPosition($event);
      this.hostElement.classList.toggle('active');
      this.toggleMouseMoveEvent();
    }
  }

  /**
   * Updates the position of the background according to the event clientX/Y
   */
  private toggleMouseMoveEvent() {
    const event = 'mousemove';
    if (this.zooming) {
      this.hostElement.addEventListener(event, this.mouseMoveListener);
    } else {
      this.hostElement.removeEventListener(event, this.mouseMoveListener);
    }
  }

  /**
   * Updates the native element background.
   * If the zoom is active the image is set as background, otherwise the background is cleaned
   */
  private updateBackground() {
    if (this.zooming) {
      this.hostElement.style.background = `url(${this.imageUrl})`;
    } else {
      this.clearBackground();
    }
  }

  /**
   * Clears background style of the host element
   */
  private clearBackground() {
    this.hostElement.style.background = '';
    this.hostElement.style.backgroundPosition = '';
  }

  /**
   * Updates the background position according to the cursor position
   * @param $event MouseEvent
   */
  private updateBackgroundPosition($event: MouseEvent) {
    const offsetX = $event.clientX - this.clientRect.left;
    const offsetY = $event.clientY - this.clientRect.top;
    this.hostElement.style.backgroundPositionX =
      100 * (offsetX / this.hostElement.clientWidth) + '%';
    this.hostElement.style.backgroundPositionY =
      100 * (offsetY / this.hostElement.clientHeight) + '%';
  }
}
