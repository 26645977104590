import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'atx-custom-icon',
  templateUrl: './custom-icon.component.html',
  styleUrls: ['./custom-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class CustomIconComponent {
  /** Id of the icon as it is defined in the sprites file */
  @Input() public icon = '';
  /* Normally the path would be passed, in pro of having small sprite files per feature
    - Like product-sprites for product icons.
    - noos-sprites for noos specific icons, that don't apply for a specific environment.
    Note that the path is limited by CORS so it needs to be relative or a proxy needs to be in place.
   */
  @Input() public path = 'static/images/icons/custom-sprites.svg';

  /** Size class of the icon */
  @Input() public size = '';

  /**
   * Returns the concatenation of the path and the icon id
   */
  public get iconRef() {
    return `${this.path}#${this.icon}`;
  }
}
