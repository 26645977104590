import { ChangeDetectionStrategy, Component } from '@angular/core';

/**
 * Zoom Document icon component
 */
@Component({
  selector: 'atx-zoom-document-icon',
  templateUrl: './fa-zoom-document.icon.html',
  styleUrls: ['./fa-zoom-document.icon.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class ZoomDocumentIconComponent {}
